import { css } from "@emotion/core"
import {
  Button,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import Container from "@material-ui/core/Container"
import GatsbyImage from "gatsby-image"
import React from "react"
import Section from "./Section"
import Stack from "./Stack"

const LeftRight = ({
  children,
  title,
  actionTitle,
  actionLink,
  image,
  reverse,
  colored,
}) => {
  const textBlockStyle = css`
    //background-color: #ccc;
    color: ${colored ? "#fff" : "inherit"};
    padding: 16px;
  `
  const theme = useTheme()
  const matchUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Section colored={colored}>
      <Container maxWidth={"lg"}>
        <Grid
          container
          alignItems={"center"}
          direction={reverse ? "row" : "row-reverse"}
        >
          <Grid item xs={12} md={6}>
            <Hidden mdUp>
              <Typography css={textBlockStyle} variant={"h2"}>
                {title}
              </Typography>
            </Hidden>
            <GatsbyImage
              fluid={image.childImageSharp.fluid}
              objectFit="contain"
              objectPosition="50% 50%"
              style={{
                maxWidth: `${matchUp ? "100%" : "70%"}`,
                margin: "0 auto",
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack space={64} css={textBlockStyle}>
              <div>
                <Hidden smDown>
                  <Typography
                    color={"inherit"}
                    variant={"h2"}
                    css={css`
                    margin-bottom: 32px;
                  `}
                  >
                    {title}
                  </Typography>
                </Hidden>
              </div>
              {children}
              <Grid
                container
                justify={matchUp ? "flex-start" : "center"}
                spacing={4}
              >
                {actionTitle && (
                  <Grid item>
                    <Button
                      href={actionLink}
                      variant={"outlined"}
                      size={"large"}
                      color={colored ? "secondary" : "primary"}
                    >
                      {actionTitle}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default LeftRight
