import React, { useState } from "react"
import Section from "./Section"
import Container from "@material-ui/core/Container"
// import SwipeableViews from "react-swipeable-views"
// import { autoPlay } from "react-swipeable-views-utils"
import { css } from "@emotion/core"
import { Grid, Typography } from "@material-ui/core"
import CarouselItem from "./CarouselItem"

import Hidden from "@material-ui/core/Hidden"
import Fab from "@material-ui/core/Fab"
import { NavigateBefore, NavigateNext } from "@material-ui/icons"
import Pagination from "./Pagination"
import Slider from "react-slick"


// const AutoPlaySwipeableViews = autoPlay(SwipeableViews)


const buttonStyle = css`
  margin-top: 300px;
`

const Carousel = ({ items, autoplay, interval }) => {
  const [activeStep, setActiveStep] = useState(0)

  const [sliderRef, setSliderRef] = useState()

  const itemCount = items.length


  const handleNext = () => {
    sliderRef.slickNext()
  }

  const handleBack = () => {
    sliderRef.slickPrev()
  }

  const handleStepChange = step => {
    sliderRef.slickGoTo(step)
  }

  const sliderOptions = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveStep(next),
  }

  return (
    <Section largeSpacing>
      <Container maxWidth={"md"}>
        <Typography
          variant={"h2"}
          css={css`
            padding-bottom: 64px;
          `}
        >
          Made with MobileUI
        </Typography>

        <Grid container justify="center" spacing={4}>
          <Hidden smDown>
            <Grid item sm={2}>
              <Fab
                href={null}
                color={"secondary"}
                css={buttonStyle}
                size="medium"
                onClick={handleBack}
              >
                <NavigateBefore/>
              </Fab>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={8}>
            <Slider{...sliderOptions} ref={slider => setSliderRef(slider)} >
              {
                items &&
                items.map((item, index) => {
                  return <CarouselItem key={index} {...item} />
                })
              }
            </Slider>
            {/*<AutoPlaySwipeableViews*/}
            {/*  index={activeStep}*/}
            {/*  onChangeIndex={handleStepChange}*/}
            {/*  interval={interval}*/}
            {/*  autoplay={autoplay}*/}
            {/*  springConfig={{*/}
            {/*    duration: "0.5s",*/}
            {/*    easeFunction: "cubic-bezier(0.15, 0.3, 0.25, 1)",*/}
            {/*    delay: "0s",*/}
            {/*    tension: 300,*/}
            {/*    friction: 30,*/}
            {/*  }}*/}
            {/*  containerStyle={{ alignItems: "center" }}*/}
            {/*  enableMouseEvents*/}
            {/*>*/}
            {/*  {items &&*/}
            {/*    items.map((item, index) => {*/}
            {/*      return <CarouselItem key={index} {...item} />*/}
            {/*    })}*/}
            {/*</AutoPlaySwipeableViews>*/}
          </Grid>
          <Hidden smDown>
            <Grid item sm={2}>
              <Fab
                href={null}
                color={"secondary"}
                css={[buttonStyle]}
                size="large"
                onClick={handleNext}
              >
                <NavigateNext/>
              </Fab>
            </Grid>
          </Hidden>
          <Grid item xs={12} style={{ paddingTop: "16px" }}>
            <Pagination
              itemCount={itemCount}
              activePage={activeStep}
              onChangeIndex={handleStepChange}
            />
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default Carousel
