import React from "react"
import GatsbyImage from "gatsby-image"
import Typography from "@material-ui/core/Typography"
import GooglePlayBadge from "../assets/svgs/GooglePlayBadge.svg"
import AppStoreBadge from "../assets/svgs/AppStoreBadge.svg"
import { css } from "@emotion/core"


const badgeWrapperStyle = css`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

const badgeStyle = css`
  width: 100%;
  max-width: 160px;
  margin-left: 16px;
  margin-right: 16px;
`

const CarouselItem = ({ image, title, subtitle, appStoreLink, googlePlayLink }) => {
  return (
    <div
      css={css`
        position: relative;
        text-align: center;
        width: 100%;
      `}
    >
      <GatsbyImage
        fluid={image.childImageSharp.fluid}
      />
      <Typography variant={"h4"} component={"h3"}>
        {title}
      </Typography>
      <Typography variant={"subtitle1"}>{subtitle}</Typography>
      <div css={badgeWrapperStyle}>
        {appStoreLink && (
          <a href={appStoreLink} target="_blank" rel="noopener noreferrer" css={badgeStyle}>
            <AppStoreBadge />
          </a>
        )}
        {googlePlayLink && (
          <a href={googlePlayLink} target="_blank" rel="noopener noreferrer" css={badgeStyle}>
            <GooglePlayBadge />
          </a>
        )}
      </div>
    </div>
  )
}


export default CarouselItem
