import { css } from "@emotion/core"
import { Grid, Typography, Button } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React from "react"
import Section from "./Section"

const wrapperStyle = css`
  position: relative;
  text-align: left;
`

const textBlockStyle = css`
  padding: 24px;
`

const FullscreenDemo = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "OneToolSuite.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Section css={wrapperStyle}>
      <Container
        maxWidth={"lg"}
        css={css`
          position: relative;
        `}
      >
        <Typography variant={"h2"} css={textBlockStyle} align={"center"}>
          Tool Suite for Android Studio
        </Typography>
        {data.image && (
          <GatsbyImage
            fluid={data.image.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            style={{ maxHeight: "1000px" }}
          />
        )}
        <Grid container justify="center">
          <Grid item xs={12} sm={6} lg={6}>
            <Typography color={"secondary"} css={textBlockStyle}>
              The MobileUI Plugin for Android Studio brings cross-platform
              development to your favorite IDE. Create, build and debug your
              apps on Android and iOS.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Typography color={"secondary"} css={textBlockStyle}>
              With the <strong>Live Preview</strong>, you see changes to your
              layouts in real-time. Push your productivity to the max with
              development cycles in milliseconds.
            </Typography>
          </Grid>
          <Grid xs={12} item>
              <Button
                style={{
                  margin: "24px",
                  textAlign: "center",
                }}
                href={"https://docs.mobileui.dev/mobileui-tool-suite/"}
                variant={"outlined"}
                size={"large"}
                color={"secondary"}
              >
                Learn more about Tool Suite
              </Button>
          </Grid>
        </Grid>
      </Container>

      {/*<div css={innerStyle}>*/}

      {/*</div>*/}
    </Section>
  )
}

export default FullscreenDemo
