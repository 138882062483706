import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

const Inline = ({ children, space, css: additionalCss, ...props }) => {
  return (
    <div css={[css`display: inline-flex; > * {margin-right: ${space}px}`, additionalCss]} {...props}>
      { children }
    </div>
  )
}

Inline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  space: PropTypes.oneOf([4, 8, 16, 32, 64, 128])
}

export default Inline
