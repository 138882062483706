import React from "react"
import Typography from "@material-ui/core/Typography"
import Section from "./Section"
import { Container } from "@material-ui/core"
import { css } from "@emotion/core"

const quoteStyle = css`
  font-size: 2rem !important;
  font-weight: 200 !important;
  margin-bottom: 16px !important;

`

const Quote = ({}) => {
  return (
    <Section>
      <Container id={"Quote"} css={css`min-height: 300px`} component={Section} maxWidth={"md"} >

        {/*<OverPack playScale={0.3} css={css`height: 100%`} always={false} >*/}

        {/*  <QueueAnim key={0} type={"bottom"} interval={200} duration={600} >*/}

        <Typography key={1} css={quoteStyle}>
          "What new technology does is create new opportunities to do a job that customers want done."
        </Typography>
        <Typography key={2}>- Tim O'Reilly</Typography>
        {/*  </QueueAnim>*/}


        {/*</OverPack>*/}
      </Container>
    </Section>
  )
}

Quote.propTypes = {}

export default Quote
