import React, { Children } from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import { css, jsx } from "@emotion/core"

const cloneElement = (element, props) =>
  jsx(element.type, {
    key: element.key,
    ref: element.ref,
    ...element.props,
    ...props,
  })

const Stack = ({
  component = "div",
  children,
  space = 8,
  align = "left",
  dividers = false,
  colored,
  css,
  ...props
}) => {
  const spacing = space / 2
  // const stackItems = Children.toArray(children)
  // const margin = css`margin-bottom: ${space}px`
  return (
    <Box
      component={component}
      style={{
        marginBottom: `-${spacing}px`,
        textAlign: align,
        marginTop: `-${spacing}px`,
        overflow: "hidden",
      }}
      css={css}
      {...props}
    >
      {Children.map(children, (child, index) => {
        return (
          <>
            {dividers && index > 0 && <Divider light={colored} />}
            {React.cloneElement(child, {
              style: {
                marginBottom: `${spacing}px`,
                marginTop: `${spacing}px`,
              },
              css: child.props.css,
            })}
          </>
        )
      })}
      {dividers && <Divider light={colored} />}
    </Box>
  )
}

Stack.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  space: PropTypes.oneOf([4, 8, 16, 30, 32, 40, 64, 128]),
}

export default Stack
