import React, { useState } from "react"
import PropTypes from "prop-types"
import PaginationDot from "./PaginationDot"
import _floor from "lodash.floor"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core"

const styles = {
  root: {},
}

class Pagination extends React.Component {
  handleClick = (event, index) => {
    this.props.onChangeIndex(index)
  }

  render() {
    const { activePage, itemCount, itemsPerPage, colored, matchUpSm, matchUpMd } = this.props
    // const [itemsPerPageWithMedia, setItemsPerPageWithMedia] = useState(itemsPerPage[0])

    let itemsPerPageWithMedia = itemsPerPage[0]

    if(matchUpSm) {
      itemsPerPageWithMedia = itemsPerPage[1]
    }
    if (matchUpMd) {
      itemsPerPageWithMedia = itemsPerPage[2]
    }

    const children = []
    const pages = _floor(itemCount / itemsPerPageWithMedia)

    // const active = activePage itemsPerPageWithMedia >

    for (let i = 0; i < pages; i++) {
      children.push(
        <PaginationDot key={i} activePage={i * itemsPerPageWithMedia} colored={colored}
                       active={i === (activePage / itemsPerPageWithMedia)} onClick={this.handleClick}/>,
      )
    }

    return <div style={styles.root}>{children}</div>
  }
}

Pagination.propTypes = {
  onChangeIndex: PropTypes.func.isRequired,
}

// Set default props
Pagination.defaultProps = {
  itemsPerPage: [1, 1, 1],
  colored: false,
}

export default Pagination
